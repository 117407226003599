import {
  Application,
  OutgoingApplication,
  PaymentTypes,
  PaymentAgreementDocument,
  Disclosure,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const APPLICATION_URI = '/api/application';

type DisclosureConfig = {
  name: string;
  requiresChecksum: boolean;
};

const REQUIRED_DISCLOSURES: Record<PaymentTypes, DisclosureConfig[]> = {
  INSTALLMENTS: [
    { name: 'credit-score-notice', requiresChecksum: false },
    { name: 'loan-agreement', requiresChecksum: true },
    { name: 'tila', requiresChecksum: false },
  ],
  SPLITPAY: [
    { name: 'credit-score-notice', requiresChecksum: false },
    { name: 'loan-agreement-sp', requiresChecksum: true },
    { name: 'checkout-auto-enroll-sp', requiresChecksum: false },
    { name: 'payment-full-terms', requiresChecksum: false },
  ],
};

export async function applicationPrepareCheckout(
  {
    application,
    paymentAgreementDocument,
    paymentAgreementID,
    iovationBlackbox,
    paymentMethodID,
    isSplitPay,
  }: {
    application: Application;
    paymentAgreementDocument: PaymentAgreementDocument;
    paymentAgreementID: string;
    iovationBlackbox?: string | null;
    isSplitPay?: boolean;
    paymentMethodID?: string | null;
  },
  apiFetch: APIFetch
): Promise<HandlerResponse<Application>> {
  const body = {
    ...application,
    disclosures: application.disclosures || [],
    paymentAgreementID,
    ...(paymentMethodID ? { paymentMethodID } : {}),
  };

  if (iovationBlackbox) {
    body.clientInfo = [
      {
        type: 'iovationBlackbox',
        value: iovationBlackbox,
      },
    ];
  }

  const paymentProductType = isSplitPay ? 'SPLITPAY' : 'INSTALLMENTS';

  for (const { name, requiresChecksum } of REQUIRED_DISCLOSURES[
    paymentProductType
  ]) {
    const newDisclosure: Disclosure = {
      type: name,
    };

    if (requiresChecksum) {
      newDisclosure.checksum = paymentAgreementDocument.checksum;
    }

    body.disclosures.push(newDisclosure);
  }

  if (!body.disclosures.find(({ type }) => type === 'E_SIGN')) {
    body.disclosures.push({
      type: 'E_SIGN',
      checksum: paymentAgreementDocument.checksum,
    });
  }
  const res: APIResponse<Application> = await apiFetch<
    Application,
    OutgoingApplication
  >(
    // @TODO - this doesn't match the api spec?
    // https://github.com/getbread/application/blob/master/api/rest/application.openapi2.json#L71
    // "/application/{applicationid}/checkout/{agreementid}"
    `${APPLICATION_URI}/${application?.id}/prepare-checkout`,
    { body },
    Application
  );
  return handleResponse(res);
}
